<template>
  <div>
    <v-layout row wrap pa-4 wrap-dash pf>
      <!-- sm7 md8 -->
      <v-flex sm12>
        <div class="my-panel">
          <div class="display-flex">
            <div class="photo-user-panel">
              <img :src="getUrl()" alt />
            </div>
            <div class="info-user-panel pl-4">
              <h2>{{ personFullName }}</h2>
              <div>
                <Icon
                  name="fal fa-envelope"
                  size="16"
                  colorFont="rgba(255, 255, 255, .5)"
                />
                {{ personEmail }}
              </div>
              <div>
                <!-- se não tiver localização, inserir botão para completar perfil -->
                <router-link
                  to="/perfil"
                  tag="button"
                  class="v-btn sm mx-0 mt-2 btn-white"
                  v-if="personCity == 0"
                >
                  <Icon name="fal fa-user-edit" size="16" />Complete seu perfil
                </router-link>
                <!-- se não tiver localização -->
                <span v-if="personCity != 0">
                  <Icon
                    name="far fa-map-marker-alt"
                    size="16"
                    colorFont="rgba(255, 255, 255, .5)"
                  />
                  {{ personCity }}, {{ personState }}
                </span>
              </div>
              <div v-show="false" class="item-status-profile">
                <div>
                  <Icon name="fal fa-star" size="16" colorFont="#C3C6D5" />12
                </div>
                <div>
                  <Icon
                    name="fal fa-hands-heart"
                    size="16"
                    colorFont="#C3C6D5"
                  />3
                </div>
                <div>
                  <Icon
                    name="fal fa-binoculars"
                    size="16"
                    colorFont="#C3C6D5"
                  />12
                </div>
              </div>
            </div>
          </div>
          <div v-show="false" class="level-profile">
            <div class="points">
              <h2>Nível Prata</h2>
              <span> <strong>1200</strong> / 3500 pontos </span>
              <a href="javascript:;">Meus benefícios</a>
            </div>
            <div class="trophy-profile">
              <div class="chart-container" style="--value: 40">
                <Icon name="fal fa-trophy-alt" size="48" colorFont="#fff" />
                <svg class="chart">
                  <circle class="base" />
                  <circle class="pie" />
                </svg>
              </div>
              <svg style="height: 0; width: 0">
                <defs>
                  <linearGradient
                    id="linear01"
                    x1="0%"
                    y1="0%"
                    x2="50%"
                    y2="0%"
                  >
                    <stop offset="0%" stop-color="#11F8BB" />
                    <stop offset="100%" stop-color="#11F8BB" />
                  </linearGradient>
                  <linearGradient
                    id="linear02"
                    x1="0%"
                    y1="0%"
                    x2="50%"
                    y2="0%"
                  >
                    <stop offset="0%" stop-color="transparent" />
                    <stop offset="100%" stop-color="transparent" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <v-layout py-4 method-payment justify-end flex>
          <v-menu offset-y :key="`income-${incomes.length}`">
            <template v-slot:activator="{ on }">
              <v-btn
                class="dropdown-primary"
                v-on="on"
                v-if="incomes && incomes.length > 0"
              >
                <Icon
                  name="fas fa-file-alt"
                  size="16"
                  class="mr-3"
                  colorFont="#02ACB4"
                />Informes de Doações
              </v-btn>
            </template>
            <v-list>
              <v-list-tile v-for="(item, index) in incomes" :key="index">
                <v-list-tile-title @click="downloadIncome(item.key)"
                  >{{ item.company }} - {{ item.year }}</v-list-tile-title
                >
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-layout>

        <v-layout bg-white column br-6>
          <div class="my-donations-header column display-flex">
            <div>
              <Icon
                name="fal fa-hands-heart"
                size="30"
                class="mr-3"
                colorFont="#C3C6D5"
              />
              <h2>Minhas doações</h2>
            </div>
            <div style="min-width: 235px; height: 64px">
              <InputDateRange
                place-holder="Período"
                :monthsToShow="3"
                v-model="period"
                @input="getCarts()"
              ></InputDateRange>
            </div>
          </div>
          <div
            class="my-donations-scroll ph-load ph-donation-list"
            :class="{ loading: carts.length == 0 }"
          >
            <template v-if="carts != null">
              <div v-if="carts.length <= 0" class="empty-donation">
                <div class="justify-center align-center display-flex py-5">
                  <div class="box-done-task">
                    <img
                      src="../../../assets/images/donation_empty@3x.png"
                      class="mt-3 mb-2"
                      alt
                    />
                    <H2>Você ainda não doou para nenhum projeto.</H2>
                    <p>
                      Veja aqui o extrato de todos os projetos e campanhas que
                      você doou. Controle os pagamentos e emita recibos.
                    </p>
                  </div>
                </div>
              </div>
              <v-expansion-panel v-else class="accordion-default px-4" :key="myCartsId">
                <v-expansion-panel-content
                  v-for="(item, i) in carts"
                  :key="i"
                  v-show="i < cartsToShowWhenNotListingAll || showShowAllCarts"
                >
                  <template v-slot:header>
                    <div class="title-acordeon">
                      <p>{{ formatterHelper.formatDate(item.checkoutDate) }}</p>
                      <div>
                        <i class="fal fa-plus-circle"></i>
                        <span class="id-donation">#{{ item.id }}</span>
                        {{ getCampaignName(item) }}
                      </div>
                      <span
                        class="active"
                        v-if="getCartOrCreditCardStatus(item)"
                        >{{ getCartOrCreditCardStatus(item) }}</span
                      >
                      <span class="active" v-if="item.donations.length > 0">{{
                        item.donations[item.donations.length - 1]
                      }}</span>
                    </div>
                    <div class="value-acordeon">
                      R$ 
                      {{ getTotalValueForAcordeon(item) }}
                    </div>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-layout column ml-30>
                        <div class="display-flex">
                          <div>
                            <div class="c-gray-dark">Valor da Doação</div> 
                            <div class="c-gray-dark" v-if="isParcelament(item)">Parcelamento</div>                           
                            <div class="c-gray-dark" v-if="item.taxValue > 0" >                              
                              <div class="red--text">Taxa do Meio de Pagamento<label class="red--text" v-if="item.creditCardParcelament > 1">, por Parcela</label></div>
                              <div class="red--text" v-if="isParcelament(item)">Taxa Total do Meio de Pagamento</div>
                              <div class="c-gray-dark">Total a ser Pago (doação + taxa)</div>
                            </div>
                            <div class="c-gray-dark">Forma de Pagamento</div>
                            <div class="c-gray-dark">Meio de Pagamento</div>
                            <div class="c-gray-dark" v-if="hasPaymentDay(item)">Dia da cobrança</div>
                            <div class="c-gray-dark" v-if="item.card != null">
                              Última doação paga no cartão
                            </div>
                          </div>
                          <div class="pl-5">
                            <div class="c-text-dark fw-600">R$ 
                              {{ formatterHelper.formatMoney(item.totalValue)}}
                            </div>
                            <div v-if="item.donationRecurrencePeriodId == 1">
                              <div class="c-text-dark fw-600" v-if="item.parcelamentId != null" >
                                {{ getParcelament(item) }}
                              </div>
                            </div>
                            <div v-if="isParcelament(item)">{{ item.creditCardParcelament }}x de R$ {{ formatterHelper.formatMoney(getParcelamentValue(item))}}</div>
                            <div class="c-text-dark fw-600" v-if="item.taxValue" >                              
                              <div class="red--text">
                                R$ {{ item.creditCardParcelament > 0 ? formatterHelper.formatMoney(item.taxValue/item.creditCardParcelament) : formatterHelper.formatMoney(item.taxValue) }}
                              </div>
                              <div class="red--text" v-if="isParcelament(item)">
                                R$ {{ formatterHelper.formatMoney(item.taxValue) }}
                              </div>
                              <div class="rc-text-dark fw-600">
                                R$ {{ formatterHelper.formatMoney(item.totalValue + item.taxValue) }}
                              </div>
                            </div>                      
                            <div class="c-text-dark f-w-bold">
                              {{ getDonationRecurrencePeriodName(item) }}
                            </div>
                            <div class="c-text-dark f-w-bold">
                              {{ getPaymentMethodName(item) }}
                            </div>
                            <div class="c-text-dark f-w-bold" v-if="hasPaymentDay(item)">
                              {{ item.paymentDay }}
                            </div>
                            <div
                              class="c-text-dark f-w-bold"
                              v-if="item.card != null"
                            >
                              **** **** **** {{ item.card.lastDigits }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="display-flex mt-4 item-receipt"
                          v-for="cartItem in item.cartItems"
                          :key="cartItem.id"
                        >
                          <div class="description-donation-pf">
                            <div class="c-red f-size-13">
                              {{ getInstitutionName(cartItem) }}
                            </div>
                            <div class="c-text-dark f-w-bold">
                              {{ cartItem.project.shortName }}
                            </div>
                            <div
                              class="c-gray-dark t-italic"
                              v-if="
                                cartItem.project.projectTypeId !=
                                projectTypes.Ticket
                              "
                            >
                              {{
                                getProjectPhaseName(
                                  cartItem.project.projectPhaseId
                                )
                              }}
                            </div>
                          </div>
                          <div
                            class="
                              display-flex
                              mt-2
                              align-item-center
                              justify-center
                            "
                          >
                            <div class="price-item-receipt">
                              R$ {{ getProjectDonationValue(item, cartItem) }}
                            </div>
                            <div 
                              class="display-flex align-item-center" 
                              v-if="cartItem.receipts.length > 0"
                            >
                              <InputSelect
                                v-if="cartItem.receipts.length > 1"
                                ref="receipts"
                                class=""
                                textAttribute="value"
                                valueAttribute="id"
                                :items="getReceiptDates(cartItem.receipts)"
                                v-model="selectedReceiptId"
                                unselectedText="Selecione uma data de recibo"
                              />
                              <v-btn
                                v-show="
                                  cartItem.receipt != null &&
                                  cartItem.receipt.status
                                "
                                class="btn-receipt orange white--text"
                                @click="generateReceipt(cartItem)"
                                :disabled="cartItem.receipts.length > 1  && parseInt(selectedReceiptId) == 0"
                              >
                                <!-- <Icon
                                  name="fal fa-file-alt"
                                  size="16"
                                  class="mr-2"                         
                                /> -->
                                Download Recibo(s)
                              </v-btn>
                            </div>
                          </div>
                        </div>
                        <div
                          class="
                            mt-4
                            display-flex
                            justify-space-between
                            buttons-acordeon
                          "
                        >
                          <!-- BOTÕES DE AÇÃO DA DOAÇÃO -->
                          <div class="display-flex mb-3 flex-wrap">                            
                            <v-btn
                              v-if="showButtonCancelDonation(item)"
                              outline
                              color="red"
                              class="mr-3"                              
                              @click="openConfirmCancelPayment(item)"
                              >Cancelar doação</v-btn
                            >
                            <v-btn
                              v-if=" showButtonEditDonation(item)"
                              outline
                              class="mr-3"                              
                              @click="openEditDonation(item)"
                              >Editar doação</v-btn
                            >
                            <v-btn
                              v-if="showButtonGenerateTicketAgain(item)"
                              v-show="item.canGenerateTicketASecondTime"
                              color="orange white--text"
                              class="mr-3"
                              @click="generateTicketAgain(item)"
                              >Emitir 2º via de boleto</v-btn
                            >
                            <v-btn
                              v-if="showButtonPayWithPix(item)"
                              v-show="showModalPix(item)"
                              color="green white--text"
                              class="mr-3"
                              @click="openModalPix(item)"
                              >Pagar com Pix</v-btn
                            >
                            <v-btn 
                              v-if="showButtonChangeDueDate(item)"
                              outline 
                              color="orange"
                              class="mr-3" 
                              @click="openNewTicketDueDate = !openNewTicketDueDate"
                              >Alterar vencimento
                            </v-btn>
                            <InputSelect
                              v-if="openNewTicketDueDate"
                              ref="ticketDueDate"
                              textLabel="Nova data de vencimento"
                              textAttribute="name"
                              valueAttribute="id"
                              :items="daysOfMonth"
                              v-model="item.paymentDay"
                              @input="changeTicketDueDate(item)"
                              style="width: 40%"
                            />
                          </div>    
                          <v-btn
                              v-if="showButtonDownloadTickets(item)"
                              outline
                              color="gray"
                              class="mr-3"
                              @click="getAllTicketPDF(item)"
                            >
                              Baixar boletos
                          </v-btn>                        
                        </div>
                        <div
                          v-if="
                            (item.paymentMethodId == paymentMethods.Ticket 
                            || item.paymentMethodId == paymentMethods.Pix
                            || item.paymentMethodId == paymentMethods.CreditCard) &&
                            item.creditCardParcelament > 1
                          "
                        >
                          <div>
                            <div
                              class="custom-checkbox"
                              v-for="expected in item.expectedCarts"
                              :key="expected.id"
                            >
                              <input
                                :id="'sid' + expected.sequenceNumber"
                                :value="'parcela-' + expected.sequenceNumber"
                                type="checkbox"
                                v-model="parcelas"
                              />
                              <label
                                class="d-flex item-receipt pa-16"
                                :class="{
                                  'opacity-6': getParcelamentIsCancel(expected),
                                }"
                                :for="'sid' + expected.sequenceNumber"
                              >
                                <div>
                                  <div
                                    style="display: flex; align-items: center"
                                  >
                                    <div class="mr-1 f-size-16">
                                      <b
                                        >Parcela
                                        {{ expected.sequenceNumber }}</b
                                      >
                                    </div>
                                    <span
                                      tabindex="0"
                                      class="v-chip v-chip--small"
                                      v-if="getParcelamentStatus(expected)"
                                    >
                                      <b
                                        class="v-chip__content white--text"
                                        :class="
                                          getParcelamentStatusColor(expected)
                                        "
                                      >
                                        <template>
                                          {{
                                            getParcelamentStatusName(expected)
                                          }}
                                        </template>
                                      </b>
                                    </span>
                                  </div>
                                  <div
                                    class="
                                      mt-sm
                                      f-size-12
                                      opacity-6
                                      text-xs-left
                                    "
                                  >
                                    Vencimento:
                                    {{
                                      formatterHelper.formatDate(
                                        expected.dueDate
                                      )
                                    }}
                                  </div>
                                </div>
                                <div style=" display: flex; justify-content: flex-end; align-items: center; "
                                >
                                  <b
                                    :class="
                                      getParcelamentValueStatusColor(expected)
                                    "
                                    >R$
                                    {{
                                      formatterHelper.formatMoney(
                                        expected.totalValue
                                      )
                                    }}</b
                                  >
                                  <v-tooltip top v-if="item.paymentMethodId == paymentMethods.Ticket && getParcelamentStatusName(expected) != 'Aprovado'" v-show="expected.cart ? expected.cart.canGenerateTicketASecondTime : false">
                                    <template v-slot:activator="{ on }" icon color="gray">
                                      <v-btn icon color="gray">
                                        <a
                                          class="fal fa-download f-size-20"
                                          :class="{active: true}"
                                          v-on="on"
                                          @click="generateTicketAgainForExpectedCart(item, expected.cart)"
                                        ></a>
                                      </v-btn>
                                    </template>
                                    <span >Boleto</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="item.paymentMethodId == paymentMethods.Ticket">
                                    <template v-slot:activator="{ on }" icon color="gray">
                                      <v-btn icon color="gray" >
                                        <a
                                          class="fal fa-file f-size-20"
                                          :class="{active: true}"
                                          v-on="on"
                                          icon
                                          color="gray"
                                          @click="getParcelamentReceipt(expected)"
                                        ></a>
                                      </v-btn>
                                    </template>
                                    <span >Recibo</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="item.paymentMethodId == paymentMethods.CreditCard && ((getParcelamentStatusName(expected) == 'Aprovado') || getParcelamentStatusName(expected) == 'Confirmado')">
                                    <template v-slot:activator="{ on }" icon color="gray">
                                      <v-btn icon color="gray" >
                                        <a
                                          class="fal fa-file f-size-20"
                                          :class="{active: true}"
                                          v-on="on"
                                          icon
                                          color="gray"
                                          @click="getParcelamentReceipt(expected)"
                                        ></a>
                                      </v-btn>
                                    </template>
                                    <span >Recibo</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="item.paymentMethodId == paymentMethods.Pix">
                                    <template v-slot:activator="{ on }" icon color="gray">
                                      <v-btn icon color="gray" @click="openModalPix(expected.cart)" >
                                        <a
                                          class="fal fa-qrcode f-size-20"
                                          :class="{active: true}"
                                          v-on="on"
                                          icon
                                          color="gray"
                                        ></a>                                      
                                      </v-btn>
                                    </template>
                                    <span >Pix</span>
                                  </v-tooltip>
                                  <div
                                    v-if="false"
                                    class="
                                      fal
                                      fa-square
                                      f-size-20
                                      mx-3
                                      unchecked
                                      gray--text
                                    "
                                  ></div>
                                  <div
                                    v-if="false"
                                    class="
                                      fas
                                      fa-check-square
                                      f-size-20
                                      primary--text
                                      checked
                                      mx-3
                                    "
                                  ></div>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>

            <hr
              class="md my-4"
            />
            <div
              class="display-flex justify-center pb-4 w-100"
              v-show="
                carts != null
                &&
                showMoreCarts
              "
            >
              <button class="btn-line" @click="getMoreCarts()">
                Ver Mais
                <span class="pill gray-light ml-2">3</span>
              </button>
            </div>
          </div>
        </v-layout>
      </v-flex>

      
      <v-flex v-if="false" sm5 md4>
        <div class="fill-height bg-white ml-4 br-6">
          <div class="my-donations-header display-flex pt-4 pr-4 pl-4">
            <div>
              <Icon
                name="fal fa-hand-holding-heart"
                size="30"
                class="mr-3"
                colorFont="#C3C6D5"
              />
              <h2 class="mb-0; nowrap">
                <small>Projetos que sigo</small>
              </h2>
            </div>
          </div>
          <div
            class="my-projects-scroll ph-load ph-project-list"
            :class="{ loading: projectsUserFollow == null }"
          >
            <template v-if="projectsUserFollow != null">
              <div
                v-if="projectsUserFollow.length <= 0"
                class="empty-donation fill-height"
              >
                <div
                  class="fill-height justify-center display-flex m-height-600"
                >
                  <div class="box-done-task">
                    <img
                      src="../../../assets/images/NoImages@2x.png"
                      width="180"
                      class="mb-0 mt-5"
                      alt
                    />
                    <h2 class="mt-0">
                      <small>Sua lista de projetos ainda está vazia!</small>
                    </h2>
                    <p>
                      Acompanhe o progresso de cada um dos projetos que você
                      doou. Avalie os resutados e confira o impacto que eles
                      causam na sociedade.
                    </p>
                    <!-- <a
										href="#"
										class="btn-primary v-btn--largee v-btn--large v-btn--round white--text v-btn mt-4"
                    >Explorar Projetos</a>-->
                  </div>
                </div>
              </div>
              <template v-else>
                <div v-for="follower in projectsUserFollow" :key="follower.id">
                  <div class="pa-4">
                    <ProjectPercent :project="follower.project" />
                  </div>
                  <BorderSeparation class="ma-0" />
                </div>
              </template>
            </template>
          </div>
        </div>
      </v-flex>
    </v-layout>

      <v-flex v-if="false" sm5 md4>
        <div class="fill-height bg-white ml-4 br-6">
          <div class="my-donations-header display-flex pt-4 pr-4 pl-4">
            <div>
              <Icon
                name="fal fa-hand-holding-heart"
                size="30"
                class="mr-3"
                colorFont="#C3C6D5"
              />
              <h2 class="mb-0; nowrap">
                <small>Projetos que sigo</small>
              </h2>
            </div>
          </div>
          <div
            class="my-projects-scroll ph-load ph-project-list"
            :class="{ loading: projectsUserFollow == null }"
          >
            <template v-if="projectsUserFollow != null">
              <div
                v-if="projectsUserFollow.length <= 0"
                class="empty-donation fill-height"
              >
                <div
                  class="fill-height justify-center display-flex m-height-600"
                >
                  <div class="box-done-task">
                    <img
                      src="../../../assets/images/NoImages@2x.png"
                      width="180"
                      class="mb-0 mt-5"
                      alt
                    />
                    <h2 class="mt-0">
                      <small>Sua lista de projetos ainda está vazia!</small>
                    </h2>
                    <p>
                      Acompanhe o progresso de cada um dos projetos que você
                      doou. Avalie os resutados e confira o impacto que eles
                      causam na sociedade.
                    </p>
                    <!-- <a
										href="#"
										class="btn-primary v-btn--largee v-btn--large v-btn--round white--text v-btn mt-4"
                    >Explorar Projetos</a>-->
                  </div>
                </div>
              </div>
              <template v-else>
                <div v-for="follower in projectsUserFollow" :key="follower.id">
                  <div class="pa-4">
                    <ProjectPercent :project="follower.project" />
                  </div>
                  <BorderSeparation class="ma-0" />
                </div>
              </template>
            </template>
          </div>
        </div>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      px-4
      pb-4
      pt-0
      v-show="
        hasPermission([permissions.CRUDInstitution, permissions.CRUDCompany])
      "
    >
      <v-flex bg-white br-6>
        <div class="my-donations-header display-flex pa-4">
          <div>
            <Icon
              name="fal fa-id-badge"
              size="30"
              class="mr-3"
              colorFont="#C3C6D5"
            />
            <h2 class="mb-0">Meus Perfis</h2>
          </div>
          <div
            v-if="
              hasPermission([
                permissions.CRUDInstitution,
                permissions.CRUDCompany,
                permissions.CRUDCouncil,
              ])
            "
          >
            <v-menu offset-y left>
              <template v-slot:activator="{ on }">
                <!-- <v-btn color="primary" dark v-on="on">Dropdown</v-btn> -->
                <v-btn fab dark color="#02acb4" btn-primary v-on="on">
                  <v-icon dark>add</v-icon>
                </v-btn>
              </template>
              <!-- inserir if para empty state -->
              <v-list>
                <v-list-tile v-if="hasPermission([permissions.CRUDCouncil])">
                  <v-list-tile-title @click="gotoCouncilProfile()">
                    <Icon class="fas fa-atlas" />Conselho
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-if="hasPermission([permissions.CRUDInstitution])"
                >
                  <!-- v-for="(item, index) in items" :key="index" @click>-->
                  <!-- <v-list-tile-title>{{ item.title }}</v-list-tile-title> -->
                  <v-list-tile-title @click="gotoInstitutionProfile()">
                    <Icon class="fas fa-hands" />Instituição
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-if="hasPermission([permissions.CRUDCompany])">
                  <v-list-tile-title @click="gotoCompanyProfile()">
                    <Icon class="fas fa-user-tie" />Empresa
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-layout box-profile-father pf pt-0 wrap>
          <!-- empty state -->
          <div
            v-if="entitiesWithPermissions.length == 0"
            class="w-100 pb-5 pt-4"
            align="center"
          >
            <div class="il il-NothingFoundHere mb-0 mt-4"></div>
            <h2>Você ainda não está associado a nenhum perfil.</h2>
            <p class="text-gray-dark">
              Entre em contato com a Abrace uma Causa para criar um novo perfil.
            </p>
            <v-btn
              @click="document.getElementById('showContact').click()"
              class="v-btn--large round btn-primary text-white mt-2"
            >
              <Icon class="fas fa-envelope mr-2" />Entre em contato
            </v-btn>
          </div>
          <!-- / empty state -->

          <router-link
            v-for="entity in entitiesWithPermissions"
            :key="entity.id"
            :to="{
              name: routes.app.DashboardCompany,
              query: { entityId: entity.id },
            }"
            style="text-decoration: none"
          >
            <MyProfileItem :entity="entity" />
          </router-link>
        </v-layout>
      </v-flex>
    </v-layout>
    <div class="work-team" v-show="false">
      <div class="image-work">
        <img src alt />
      </div>
      <div class="circle">
        <Icon
          class="icon-group"
          name="fas fa-users"
          size="36"
          colorFont="#fff"
        />
      </div>
      <div class="text-work">
        <h3>Trabalho em equipe</h3>
        <span
          >Doar faz a diferença, mas criar um grupo e levantar fundos para um
          projeto, isso sim faz a diferença.</span
        >
        <span class="mt-2"
          >Crie um time, levante fundos, ajude quem mais precisa.</span
        >
        <button>Crie um time</button>
      </div>
    </div>
    <AucAlert
      ref="cancelPaymentAlert"
      title="Você tem certeza que deseja cancelar essa doação?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="cancelPayment(selectedCart)"
    />
    <ModalEditDonation ref="editModal" />
    <ModalPix ref="modalPix" :withClose="true" />
  </div>
</template>

<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import GenerateTicket from "@/components/GenerateTicket.vue";
import ProjectPercent from "@/components/ProjectPercent.vue";
import PersonService from "@/scripts/services/person.service";
import ProjectService from "@/scripts/services/project.service";
import CartService from "@/scripts/services/cart.service";
import UserService from "@/scripts/services/user.service";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import { Routes } from "@/scripts/models/enums/routes.enum";
import ModalPix from "@/components/ModalPix.vue";
import DonationRecurrencePeriod from "@/scripts/models/enums/donationRecurrencePeriod.enum";
import {
  EntityType,
  EntityTypeList,
} from "@/scripts/models/enums/entityType.enum";
import {
  ProjectPhases,
  ProjectPhasesOnCreationPositiveCaptureStatus,
  ProjectPhasesOnCreationNegativeCaptureStatus,
  ProjectPhasesText,
} from "@/scripts/models/enums/projectPhases.enum";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum";
import MyProfileItem from "@/components/user/MyProfileItem.vue";
import CreditCardCartDetailsStatus from "@/scripts/models/enums/creditCardCartDetailsStatus.enum";
import CartStatus from "@/scripts/models/enums/cartStatus.enum";
import { mapGetters, mapState } from "vuex";
import Permissions from "@/scripts/models/enums/permissions.enum";
import CommonHelper from "@/scripts/helpers/common.helper";
import { flat } from "@/scripts/helpers/list.helper";
import DocService from "@/scripts/services/doc.service";
import ModalEditDonation from "../campaign/modal/ModalEditDonation.vue";
import VolunteeringRegistrationOfferService from "@/scripts/services/volunteeringRegistrationOffer.service.js"

export default {
  components: {
    GenerateTicket,
    ProjectPercent,
    MyProfileItem,
    AucAlert,
    ModalEditDonation,
    ModalPix,
  },
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      personService: new PersonService(),
      projectService: new ProjectService(),
      volunteeringRegistrationOfferService: new VolunteeringRegistrationOfferService(),
      userService: new UserService(),
      cartService: new CartService(),
      docService: new DocService(),
      person: null,
      carts: [],
      entities: [],
      daysOfMonth: [
        { id: 1, name: 1 },
        { id: 10, name: 10 },
        { id: 20, name: 20 },
      ],
      openNewTicketDueDate: false,
      newTicketDueDate: null,
      period: [null, null],
      campaignsAndProjectsUserDonatedTo: null,
      projectsUserFollow: null,
      campaignsAndProjectsUserFollows: null,
      showShowAllCarts: false,
      cartsToShowWhenNotListingAll: 3,
      permissions: null,
      projectTypes: null,
      paymentMethods: null,
      recurrenceEnum: null,
      routes: null,
      entitiesWithPermissions: [],
      profileImage: {
        name: "oi",
        path: "",
      },
      selectedReceiptId: 0,
      parcelas: [],
      qtyOfCarts: 0,
      myCartsId: 0,
      showMoreCarts: true,
      incomes: [],
    };
  },
  created() {
    this.getProfile();
    this.getCarts();
    this.getUserEntities();
    this.getIncomes();
    this.getProjectsUserFollow();
    this.permissions = Permissions;
    this.projectTypes = ProjectTypes;
    this.paymentMethods = PaymentMethod;
    this.recurrenceEnum = DonationRecurrencePeriod;
    this.routes = Routes;
  },
  updated() {
    this.verifyURL();
  },
  computed: {
    ...mapGetters(["hasPermission"]),
    ...mapState(["permissionsLoaded"]),
    personFullName() {
      let result = "";
      if(this.person && this.person.cnpj != null){
        result = this.person.socialReason
      }else{
        if (this.person != null) {
          if (this.person.name != null) {
            result += this.person.name;
            if (this.person.lastName != null) result += " ";
          }
          if (this.person.lastName != null) result += this.person.lastName;
        }
      }
      return result;
    },
    personEmail() {
      if (this.person != null) return this.person.email;
      return "";
    },
    personCity() {
      if (this.person != null && this.person.address != null)
        return this.person.address.city;
      return "";
    },
    personState() {
      if (this.person != null && this.person.address != null)
        return this.person.address.state;
      return "";
    },
  },
  watch: {
    permissionsLoaded(newValue, oldValue) {
      if (newValue === true) {
        if (this.entities)
          this.userService_listUserEntitiesCallback(this.entities);
      }
    },
  },
  methods: {
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    getParcelamentReceipt(expected){
      this.docService.getReceiptByCartItemId(expected.id).then((data) => {
        if (data) {
          const url = window.URL.createObjectURL(this.b64toBlob(data, 'application/zip'));
          const link = document.createElement('a');
          const date = new Date().toLocaleString().replaceAll('/','-').replaceAll(':','-').replaceAll(', ','-')
          link.href = url;
          link.setAttribute('download', `Recibo-${date}.zip`);
          document.body.appendChild(link);
          link.click()
        }
      });
    },
    getTotalValueForAcordeon(item){
      if (item.expectedCarts.length > 0) {
        return this.formatterHelper.formatMoney(item.expectedCarts.reduce((acc, item) => acc + item.totalValue, 0));
      }
      if(item.statusId == CartStatus.PaymentError)
        return this.formatterHelper.formatMoney(item.totalValue);

      return this.formatterHelper.formatMoney(item.totalValue * ((item.creditCardParcelament && item.creditCardParcelament > 0) ? item.creditCardParcelament : 1))
    },
    getProjectDonationValue(item, cartItem){
      let totalValue = 0;

      if (item.expectedCarts.length > 0) {
        for (const expectedCart of item.expectedCarts) {
          totalValue += expectedCart.totalValue
        }
        return this.formatterHelper.formatMoney(totalValue);
      }
      
      return this.formatterHelper.formatMoney(cartItem.itemValue)
    },
    getMoreCarts(){
      this.qtyOfCarts += 1;
      this.cartsToShowWhenNotListingAll += 3;
      this.getCarts();
      this.myCartsId += 1;
    },
    getTotalValueWithTax(item){
      var value = item.totalValue + item.taxValue;
      value = item.totalValue + (item.taxValue * item.creditCardParcelament);
      return value;
    },
    getTotalTax(item){
      var value = item.taxValue;
      value = value * item.creditCardParcelament;
      return value;
    },
    getParcelamentValue(item){
      var value = (item.totalValue / item.creditCardParcelament) + item.taxValue;
      return value;
    },
    isParcelament(item){
      return (item.creditCardParcelament > 1 &&
      (item.paymentMethodId == this.paymentMethods.Ticket || item.paymentMethodId == this.paymentMethods.Pix || item.paymentMethodId == this.paymentMethods.CreditCard))
    },
    hasPaymentDay(item){
      return (this.isRecurrence(item) || this.isParcelament(item) && (item.paymentMethodId != this.paymentMethods.CreditCard))
    },
    showButtonCancelDonation(item){
      return item.canCancel
    },
    showButtonEditDonation(item){
      if(this.isRecurrence(item) && 
          item.paymentMethodId == this.paymentMethods.CreditCard &&
          this.getCartOrCreditCardStatus(item) !=
            'Cancelado pelo comprador' &&
          this.getCartOrCreditCardStatus(item) !=
            'Cancelado pelo vendedor' &&
          this.getCartOrCreditCardStatus(item) != 'Cancelado')
        return true;
      return false;
    },
    showButtonGenerateTicketAgain(item){
      if(item.paymentMethodId == this.paymentMethods.Ticket && 
        item.creditCardParcelament <= 1 &&
        item.canGenerateTicketASecondTime && !this.isRecurrence(item))
        return true;
      return false;
    },
    showButtonPayWithPix(item){
      if(item.paymentMethodId == this.paymentMethods.Pix &&
        !this.isRecurrence(item) && item.creditCardParcelament <= 1)
        return true;
      return false;
    },
    showButtonChangeDueDate(item){
      if(item.paymentMethodId != this.paymentMethods.CreditCard &&
        item.canCancel && (this.isRecurrence(item) || item.creditCardParcelament > 1))
        return true;
      return false;
    },
    showButtonDownloadTickets(item){
      if(item.canCancel && item.paymentMethodId == this.paymentMethods.Ticket && item.creditCardParcelament > 1)
        return true;
      return false;
    },
    calculateItemValue(projectId, item){
      let sum = 0;
      for (let index = 0; index < item.expectedCarts.length; index++) {
        const expected = item.expectedCarts[index];
        if(expected.cart == null) continue;
        const cartItem = expected.cart.cartItems.find(x => x.projectId = projectId);
        sum += cartItem.itemValue;
      }
      return parseFloat(sum.toFixed(2));
    },
    getParcelamentValueStatusColor(expected) {
      if (
        !expected.cart ||
        expected.cart.statusId == CartStatus.WaitingForApproval
      ) {
        return;
      }
      if (
        expected.cart.statusId == CartStatus.Approved ||
        expected.cart.statusId == CartStatus.ApprovedAndConfirmed
      ) {
        return "primary--text";
      }
      return "red--text";
    },
    getParcelamentIsCancel(expected) {
      return (expected.cart && expected.cart.statusId == CartStatus.Cancelled);
    },
    getParcelamentStatus(expected) {
      if(!expected.cart) return
      if (expected.cart.paymentMethodId == this.paymentMethods.CreditCard) return;

      var date = new Date();
      date = date.toISOString();

      if (
        !expected.cart ||
        (expected.cart.statusId == CartStatus.WaitingForApproval && expected.dueDate > date)
      ) {
        return false;
      }
      return true;
    },
    getParcelamentStatusColor(expected) {
      if (
        !expected.cart ||
        expected.cart.statusId == CartStatus.WaitingForApproval
      ) {
        return;
      }
      if (
        expected.cart.statusId == CartStatus.Approved ||
        expected.cart.statusId == CartStatus.ApprovedAndConfirmed
      ) {
        return "bg-primary";
      }
      return "bg-red";
    },
    getParcelamentStatusName(expected) {
      if (expected.cart) {
        var date = new Date();
        date = date.toISOString();

        if (expected.dueDate < date && expected.cart.statusId == CartStatus.WaitingForApproval) {
          return CartStatus.getName(CartStatus.Expired)
        }

        return CartStatus.getName(expected.cart.statusId);
      }
    },
    getAllTicketPDF(cart) {
      this.cartService.getAllTicketPDF(cart).then((data) => {
        if (data) {
          var sliceSize = 512;

          var byteCharacters = atob(data);
          var byteArrays = [];

          for (
            var offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
          ) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
          }

          var blob = new Blob(byteArrays, { type: "application/zip" });
          var fileName = "Boletos.zip";
          var url = URL.createObjectURL(blob);

          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = fileName;
            a.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            }, 0);
          }
        }
      });
    },
    getDate(date) {
      date = date.split("T")[0];
      return date.substr(0, 10).split("-").reverse().join("/");
    },
    openEditDonation(cart) {
      this.$refs.editModal.open(cart);
    },
    isRecurrence(cart) {
      return (
        cart.donationRecurrencePeriodId  == this.recurrenceEnum.EveryOneMonth
      );
    },
    openModalPix(cart) {
      this.$refs.modalPix.open(cart);
    },
    showModalPix(cart) {
      if (
        cart.paymentMethodId == this.paymentMethods.Pix &&
        cart.statusId == CartStatus.WaitingForApproval
      )
        return true;
      return false;
    },
    getIncomes() {
      this.docService.getIncomeCodesByCompanyAndUser(this.callbackIncome);
    },
    callbackIncome(data) {
      this.incomes = data;
    },
    downloadIncome(key) {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = process.env.VUE_APP_API_URL + "doc/incomeReport?key=" + key;
      a.click();
    },
    generateReceipt(cartItem) {
        this.docService.getAllReceiptsByCartItemId(cartItem.cartId).then((data) => {
        if (data) {
            const url = window.URL.createObjectURL(this.b64toBlob(data, 'application/zip'));
            const link = document.createElement('a');
            const date = new Date().toLocaleString().replaceAll('/','-').replaceAll(':','-').replaceAll(', ','-')
            link.href = url;
            link.setAttribute('download', `Recibos-${date}.zip`);
            document.body.appendChild(link);
            link.click()
          }
        else {
          this.selectedReceiptId = cartItem.receipts[0].id
          this.docService.showReceiptById(this.selectedReceiptId).then(function (data) {
            let a = document.createElement("a");
            a.target = "_blank";
            let chave = data.file.publicId.replace("Receipt/", "");

            a.href = process.env.BASE_URL + "ReciboDoacao?chave=" + chave;

            a.click();
        });
        }
        });
      // }
    },
    openConfirmCancelPayment(cart) {
      this.selectedCart = cart;
      this.$refs.cancelPaymentAlert.open();
    },
    changeTicketDueDate(cart) {
      this.cartService.changeTicketDueDate(cart.id, cart.paymentDay)
      .then(() => CommonHelper.swal("", "Data alterada com sucesso", "success"));
    },
    generateTicketAgain(cart) {
      this.$store.commit("SET_TICKET_CART", cart);

      let routeData = this.$router.resolve({
        name: Routes.web.GenerateTicket,
        query: { again: true },
      });
      window.open(routeData.href, "_blank");
    },
    generateTicketAgainForExpectedCart(item, cart){
      if (!cart) {
        this.generateTicketAgain(item);
      }
      else{
        this.generateTicketAgain(cart);
      }
    },
    getParcelament(cart) {
      if (cart.parcelamentId == null) return null;
      let description = "";
      if (cart.parcelament.howMany == 0) {
        description =
          cart.parcelament.description + " 1x R$ " + cart.totalValue;
      } else {
        let value = this.formatterHelper.formatMoney(cart.totalValue / cart.parcelament.howMany);
        description =
          cart.parcelament.howMany +
          "x R$ " +
          value +
          " " +
          cart.parcelament.description;
      }
      return description;
    },
    getPaymentMethodName(cart) {
      if (cart.paymentMethod != null) return cart.paymentMethod.name;
      return "";
    },
    getCardLastDigits(cart) {
      this.cartService
        .getCreditCardsLastDigits(cart.subscriptionId)
        .then((data) => {
          let bla = this.cart.find((x) => x.id == cart.id);

          //return data;
        });
    },
    getDonationRecurrencePeriodName(cart) {
      if (cart.donationRecurrencePeriodId != null)
      {
        if (cart.donationRecurrencePeriodId == 1 && cart.creditCardParcelament > 1) {
          return "Parcelado";
        }
        if (cart.donationRecurrencePeriodId == 1) {
          return "À vista"
        }
        return "Recorrente";
      }
      return "";
    },
    getInstitutionName(cartItem) {
      if (
        cartItem.project != null &&
        cartItem.project.institution != null &&
        cartItem.project.institution.entity != null
      )
        return cartItem.project.institution.entity.name;
      return "";
    },
    getCampaignName(cart) {
      if (cart.campaign != null) return cart.campaign.name;
      return "";
    },
    cancelPayment(cart) {
      this.cartService.cancelPayment(cart).then(() => {
        CommonHelper.swal("", "Doação cancelada com sucesso", "success");
        this.carts = [];
        this.getCarts();
      });
    },
    getCartOrCreditCardStatus(cart) {
      if (cart.creditCardCartDetails != null)
        return CreditCardCartDetailsStatus.getName(
          cart.creditCardCartDetails.statusId,
          cart.statusId,
          cart.donationRecurrencePeriodId
        );

      if (cart.expectedCarts.length > 1) {
        var canceledCarts = 0;
        var hasCartInWaitingForApproval = false;
        var statusToReturn = CartStatus.WaitingForApproval;

        cart.expectedCarts.forEach(item => {
          if(!item.cart) return;
          if (item.cart.statusId == CartStatus.Cancelled) {
            canceledCarts++;
          }

          if (item.cart.statusId == CartStatus.WaitingForApproval) {
            hasCartInWaitingForApproval = true;
          }

          if (item.cart.statusId != CartStatus.Cancelled) {
            statusToReturn = item.cart.statusId;
          }
        });
        
        if (canceledCarts == cart.expectedCarts.length) {
          return CartStatus.getName(cart.statusId);
        }

        if (hasCartInWaitingForApproval) {
          return "Ativo";
        }

        return CartStatus.getName(statusToReturn);
      }

      return CartStatus.getName(cart.statusId);
    },
    getProjectsUserFollow() {
      this.projectService.findProjectsUserFollow().then(
        function (data) {
          this.projectsUserFollow = data;
        }.bind(this)
      );
    },
    gotoInstitutionProfile() {
      this.$router.push({ name: Routes.app.InstitutionProfile });
    },
    gotoCompanyProfile() {
      this.$router.push({ name: Routes.app.CompanyProfile });
    },
    gotoCouncilProfile() {
      this.$router.push({ name: Routes.app.CouncilProfile });
    },
    getUrl() {
      let url =
        "https://ui-avatars.com/api/?background=F1F2F5&color=80849A&size=256&name=";
      if (this.person != null) url += this.person.name.split().join("+");
      return url;
    },
    getProfile() {
      this.personService.getProfile(this.getProfileCallback)
    },
    getCarts() {
      this.cartService
        .getLoggedUserClosedCarts(this.period[0], this.period[1], this.qtyOfCarts)
        .then(this.cartService_getLoggedUserClosedCartsCallback);
    },
    verifyURL() {
      if (this.$route.fullPath.includes("cartId=")) {
        var editDonationCart = this.carts.find(
          (cart) => cart.id == this.$route.query.cartId
        );
        if (editDonationCart.paymentMethodId == PaymentMethod.Pix) {
          this.openModalPix(editDonationCart);
        } else {
          this.openEditDonation(editDonationCart);
        }
      }
    },
    getUserEntities() {
      this.userService
        .listUserEntities()
        .then(this.userService_listUserEntitiesCallback);
    },
    getProfileCallback(data) {
      data.birthDate = this.formatterHelper.formatDate(data.birthDate);
      this.person = data;
    },
    getProjectPhaseName(projectPhaseId) {
      for (let projectPhase in ProjectPhasesText) {
        if (ProjectPhasesText[projectPhase].id == projectPhaseId)
          return ProjectPhasesText[projectPhase].name;
      }
    },
    cartService_getLoggedUserClosedCartsCallback(carts) {
      var totalCarts = this.carts.length;
      carts.forEach((cart) => {
        if (cart.expectedCarts && cart.expectedCarts.length > 0) {
          if (!cart.expectedCarts[0].cart) {
            let tempCart = Object.assign({}, cart);
            tempCart.expectedCarts = [];
            cart.expectedCarts[0].cart = tempCart;
          }
        }
        this.carts.push(cart);
      });

      if (totalCarts == this.carts.length) {
        this.showMoreCarts = false;
      }

      if (this.period[0] && this.period[1]) {
        this.carts = carts;
        this.cartsToShowWhenNotListingAll = carts.length;
        this.showMoreCarts = false;
      }
    },
    getCampaignProjects(campaign) {
      let allCampaignProjects = flat(
        this.carts
          .filter((cart) => cart.campaignId == campaign.id)
          .map((cart) => cart.cartItems)
      ).map((cartItem) => cartItem.project);
      let campaignProjectsDistinctIds = Array.from(
        new Set(allCampaignProjects.map((project) => project.id))
      );
      let result = campaignProjectsDistinctIds.map((id) =>
        allCampaignProjects.find((p) => p.id == id)
      );
      return result;
    },
    existEncouragedProject() {
      let exist = false;
      this.carts.forEach((cart) => {
        if (!exist) {
          cart.cartItems.forEach((item) => {
            if (
              item.project.projectTypeId == this.projectTypes.ProjetoIncentivado
            ) {
              exist = true;
            }
          });
        }
      });
      return exist;
    },
    userService_listUserEntitiesCallback(data) {
      this.entities = data;
      this.entitiesWithPermissions = this.entities.filter(
        this.filterByPermission.bind(this)
      );
    },
    getReceiptDates(receipts){
      if(!receipts) return [];
      return receipts.map(x => { return {id: x.id, value: this.getDate(x.createdAt)}});
    },
    filterByPermission(item) {
      return this.hasPermission(
        [
          Permissions.CRUDInstitution,
          Permissions.CRUDCompany,
          Permissions.CRUDCouncil,
          Permissions.CRUDProject,
          Permissions.CRUDCampaign,
          Permissions.UpdateInstitution,
          Permissions.UpdateCompany,
          Permissions.UpdateCouncil,
        ],
        item.id,
        false
      );
    },
    myVolunteeringMock () {
      const data = {
        campaign: {
          name: "Campanha",
          id: 2354
        },
        status: "Ativo",
        attributeOffer: {
          institutionName: "ONG",
          attributeOfferName: "Semeando Oportunidades",
          address: {
            street: "Rua Galdino Remoto",
            number: "134",
            city: "Cidade Grande",
            CEP: "18000-000"
          },
          date: {
            date: "21/03/2023",
            hour: "15:00 as 19:00",
            days: ['tuesday','thursday']
          }
      }
    }
    return data
  },
}
}
</script>